<template>
  <div class="device-settings">
    <div class="device-settings-wrapper">
      <img src="/static/img/device-settings/thumb-device-settings.svg" class="thumb" width="363" height="299" />
      <h1>GENERAL SETTINGS</h1>
      <form @submit.prevent="initialSettings()">
        <!-- <div class="field">
					<div class="fieldinput fieldselect">
						<select v-model="device">
							<option value="" disabled selected>Select Device</option>
							<template v-for="item in deviceoption">
								<option :value="item.id">{{item.value}}</option>
							</template>
						</select>
					</div>
				</div> -->
        <!-- <div class="field">
          <div class="fieldinput fieldselect">
            <select v-model="screen">
              <option value="" disabled selected>Select Screen</option>
              <template v-for="(item, i) in screenoption">
                <option :key="i" :value="item">{{ item.value }}</option>
              </template>
            </select>
          </div>
        </div> -->

        <SelectScreenFrom
          tabindex="0"
          :lists="screenoption"
          :is-screen="true"
          :type="'store'"
          :selected="screen"
          title="Select Screen"
          field="value"
          @change="changeScreen($event)"
        />

        <div v-if="loading_data || loading_stores" class="field">
          <div class="fieldinput fieldselect">
            <div id="loaderspinwrap" class="loaderspinwrap form-loader"><div class="loaderspin"></div></div>
          </div>
        </div>
        <div v-else class="field">
          <!-- <div class="fieldinput fieldselect">
						<select v-model="location" @change="changeLocation($event)">
							<option value="" disabled selected>Select Location</option>
							<template v-if="screen.key === 'vmcola'">
								<template v-for="(item,j) in location_vmcola">
									<option :value="j" :key="j">{{item.name}}</option>
								</template>
							</template>
							<template v-else>
								<template v-for="(item,k) in locationoption">
									<option :value="k" :key="k">{{item.name}}</option>
								</template>
							</template>
						</select>
					</div> -->
          <SelectDeviceFrom
            tabindex="0"
            :loading="loading_pagination"
            :lists="list_options"
            :type="screen && screen.key == 'vmcola' ? 'vmcola' : 'store'"
            :selected="location"
            title="Select Location"
            field="name"
            @change="changeLocation($event)"
            @load-more="doCallback"
          />
        </div>
        <button v-if="screen !== '' && location !== ''" tabindex="0" type="submit" class="submit btn button">
          <template v-if="!loading"> SUBMIT </template>
          <div v-else id="loaderspinwrap" class="loaderspinwrap"><div class="loaderspin"></div></div>
        </button>
        <button v-else tabindex="0" type="submit" class="submit btn button" disabled>SUBMIT</button>
      </form>
    </div>
  </div>
</template>

<script>
import ls from '@/components/helpers/local-storage';
// import SelectDeviceFrom from '@/components/global/select-device-form';

export default {
  name: 'DeviceSettings',
  components: {
    SelectScreenFrom: () => import('@/components/global/select-device-form'),
    SelectDeviceFrom: () => import('@/components/global/select-device-form'),
  },
  data() {
    return {
      device: '',
      screen: '',
      location: '',
      deviceoption: [
        { id: 1, value: 'iPad 6' },
        { id: 2, value: 'Full HD TV' },
      ],
      screenoption: [
        { id: 1, key: 'checkin-station', value: 'CheckIn Station', url: '/checkin-station/home' },
        { id: 2, key: 'self-checkout', value: 'Self Checkout', url: '/self-checkout/home' },
        { id: 3, key: 'lipstick-bar', value: 'Lipstick Bar', url: '/lipstickbar/home' },
        { id: 4, key: 'ranking', value: 'Ranking', url: '/ranking/' },
        { id: 5, key: 'recommendation-machine', value: 'Recommendation Machine', url: '/recommendation/splash' },
        { id: 6, key: 'register', value: 'Register', url: '/register/' },
        { id: 7, key: 'smart-mirror', value: 'Smart Mirror', url: '/smart-mirror/' },
        { id: 8, key: 'soco-mask-station', value: 'Soco Mask Station', url: '/mask-station/home' },
        { id: 9, key: 'vending-machine', value: 'Vending Machine', url: '/vending-machine/home' },
        { id: 10, key: 'vmcola', value: 'VM Cola', url: '/vmcola' },
        { id: 11, key: 'big-bang-boom-vn', value: 'Big Bang Boom VN', url: '/vending-machine-vn/home?isvn=true' },
        { id: 12, key: 'checkin-station-vn', value: 'CheckIn Station VN', url: '/checkin-station/home?isvn=true' },
        { id: 13, key: 'checkin', value: 'CheckIn', url: '/checkin/' },
        { id: 14, key: 'dandelion-store', value: 'Dandelion - Price Checker', url: '/dandelion-store' },
        {
          id: 15,
          key: 'soco-vending-machine-event',
          value: 'Soco Vending Machine Event',
          url: '/soco-vending-machine-event/home',
        },
      ],
      // locationoption: [],
      // location_vmcola: [],
      locationVmColaDetail: '',
      loading: false,
    };
  },
  computed: {
    loading_stores() {
      return this.$store.state.loading_stores;
    },
    loading_data() {
      return this.$store.state.loading_vmcola;
    },
    loading_pagination() {
      return this.$store.state.loading_pagination;
    },
    locationoption() {
      return this.$store.state.stores;
    },
    location_vmcola() {
      return this.$store.state.vmcola;
    },
    list_options() {
      return this.screen && (this.screen.key == 'vmcola' || this.screen.key == 'soco-vending-machine-event')
        ? this.location_vmcola
        : this.locationoption;
    },
  },
  watch: {
    screen(val) {
      this.location = -1;
      if (val.key === 'vmcola' || val.key === 'soco-vending-machine-event') {
        // this.getVmStore()
        this.$store.dispatch('getVmStores');
      }
    },
  },
  created() {
    this.$store.dispatch('VendingMachineDevice', false);

    this.resetCartVmCola();
    if (this.$route.name == 'DeviceSettings') {
      // this.getVmStore()
      this.$store.dispatch('getPhysicalStores');
    }
    // this.locationoption = Config.store_lists
  },
  methods: {
    initialSettings() {
      this.loading = true;
      let store_key =
        this.screen.key == 'vmcola' || this.screen.key == 'soco-vending-machine-event' ? 'store_id' : '_id';
      if (['checkin-station', 'lipstick-bar'].includes(this.screen.key)) {
        store_key = 'id';
      }
      const loc = store_key ? this.list_options[this.location][store_key] : this.location;
      const store_alias = this.list_options[this.location]['alias'];

      this.setStoreDetail(this.list_options[this.location]);

      if (this.screen.key === 'vending-machine') {
        this.$store.dispatch('VendingMachineDevice', true);
      }

      this.$store.dispatch('saveStoreIdCookies', loc);
      this.setVmColaLocDetail();

      setTimeout(
        function () {
          if (this.screen.key === 'dandelion-store') {
            const url = `${this.screen.url}/${store_alias}`;
            window.open(url, '_blank');
            this.loading = false;
          } else {
            this.$router.push(this.screen.url);
          }
        }.bind(this),
        400
      );
    },
    setVmColaLocDetail(value) {
      this.$store.dispatch('vmColaLocationDetailCookies', this.locationVmColaDetail);
    },
    setStoreDetail(val) {
      this.$store.dispatch('saveStoreDetail', val);
    },
    changeScreen($event) {
      const selected_screen = this.screenoption.find((sc) => sc.id === $event.target.id);
      if (selected_screen) {
        this.screen = selected_screen;
      }
    },
    changeLocation(event) {
      const target = event.target.value;
      this.location = target;
      const loc = target > -1 ? this.list_options[target] : '';
      if (this.screen.key === 'vmcola' && Object.keys(loc).length) {
        this.locationVmColaDetail = loc;
      } else {
        this.locationVmColaDetail = '';
      }
    },
    resetCartVmCola() {
      ls.remove('cart');
      this.$store.dispatch('resetCart', 0);
    },
    doCallback(type) {
      if (type == 'vmcola' || this.screen.key == 'soco-vending-machine-event') {
        this.$store.dispatch('getVmStores', true);
      } else {
        this.$store.dispatch('getPhysicalStores', true);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import './device-settings';
</style>
